import React, { Component } from "react";
import {
  FormContainer,
  Label,
  Select,
  FormElement,
  Input
} from "./StyledComponents";

class AddressForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: this.props.formData,
      addressComplete: false,
      useShipping: true,
      numComplete: false,
      expiryComplete: false,
      cvcComplete: false,
      error: null
    };
    this.autocomplete = this.autocomplete;
    this.addressForm = React.createRef();
    this.address = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handlePlaceChange = this.handlePlaceChange.bind(this);
  }

  componentDidMount() {
    this.props.getFormRef(this.addressForm.current);
    const options = {
      types: ["address"],
      componentRestrictions: { country: ["us", "ca"] }
    };
    const google = window.google;
    this.autocomplete = new google.maps.places.Autocomplete(
      this.address.current,
      options
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceChange);
  }

  componentDidUpdate(prevProps, prevState) {
    prevState.formData !== this.props.formData &&
      this.setState({ formData: this.props.formData });
  }

  handlePlaceChange() {
    let places = this.autocomplete.getPlace();
    let city, state, country, zip;
    places.address_components.forEach(place => {
      place.types.forEach(type => {
        switch (type) {
          case "locality":
            city = place.long_name;
            break;
          case "administrative_area_level_1":
            state = place.long_name;
            break;
          case "country":
            country = place.long_name;
            break;
          case "postal_code":
            zip = place.long_name.replace(/\s/g, "");
            break;
          default:
            break;
        }
      });
    });
    let formData = { ...this.state.formData };
    if (country === "Canada" && zip && zip.length >= 6) {
      formData.address = places.name;
      formData.city = city;
      formData.state = state;
      formData.country = country;
      formData.zip = zip;
    } else if (country === "United States" && zip && zip.length === 5) {
      formData.address = places.name;
      formData.city = city;
      formData.state = state;
      formData.country = country;
      formData.zip = zip;
    } else {
      formData.address = places.name;
      formData.city = city;
      formData.state = state;
      formData.country = country;
    }
    this.setState({ formData }, () => {
      this.props.getFormData(this.state.formData);
    });
  }

  handleChange(e) {
    const key = e.target.name;
    const value = e.target.value;
    let formData = { ...this.state.formData };
    formData[key] = value;

    if (key === "country") {
      formData.state = "";
    }
    this.setState({ formData }, () => {
      this.props.getFormData(this.state.formData);
    });
  }

  render() {
    return (
      <div style={{ margin: "1em 0" }}>
        <form ref={this.addressForm}>
          <FormContainer>
            <FormElement>
              <Label htmlFor="email">Email</Label>
              <Input
                className="inputFix"
                id="email"
                name="email"
                type="email"
                placeholder="you@company.com"
                value={this.state.formData.email}
                onChange={e => this.handleChange(e)}
                required
              />
            </FormElement>
            <FormElement>
              <Label htmlFor="name">Name</Label>
              <Input
                id="name"
                name="name"
                type="text"
                placeholder="John Doe"
                value={this.state.formData.name}
                onChange={e => this.handleChange(e)}
                required
              />
            </FormElement>
            <FormElement>
              <Label htmlFor="company">Company</Label>
              <Input
                id="company"
                name="company"
                type="text"
                placeholder="Company"
                value={this.state.formData.company}
                onChange={e => this.handleChange(e)}
                required
              />
            </FormElement>
            <FormElement>
              <Label htmlFor="address">Address</Label>
              <Input
                innerRef={this.address}
                id="address"
                name="address"
                type="text"
                placeholder="Address"
                value={this.state.formData.address}
                onChange={e => this.handleChange(e)}
                required
              />
            </FormElement>
            <FormElement>
              <Label htmlFor="unit">Apt, suite, etc.</Label>
              <Input
                id="unit"
                name="unit"
                type="text"
                placeholder="Apt, suite, etc. (optional)"
                value={this.state.formData.unit}
                onChange={e => this.handleChange(e)}
              />
            </FormElement>
            <FormElement>
              <Label htmlFor="city">City</Label>
              <Input
                id="city"
                name="city"
                type="text"
                placeholder="City"
                value={this.state.formData.city}
                onChange={e => this.handleChange(e)}
                required
              />
            </FormElement>
            <FormElement>
              <Label htmlFor="country">Country</Label>
              <Select
                id="country"
                name="country"
                type="text"
                value={this.state.formData.country}
                onChange={e => this.handleChange(e)}
              >
                {["Canada", "United States"].map(i => {
                  return (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  );
                })}
              </Select>
            </FormElement>
            <FormElement>
              <Label htmlFor="state">State</Label>
              <Select
                id="state"
                name="state"
                type="text"
                required
                value={this.state.formData.state}
                onChange={e => this.handleChange(e)}
              >
                {this.state.formData.country === "Canada" ? (
                  <>
                    <option value="" disabled>
                      Select
                    </option>
                    {[
                      "Alberta",
                      "British Columbia",
                      "Manitoba",
                      "New Brunswick",
                      "Newfoundland",
                      "Northwest Territories",
                      "Nova Scotia",
                      "Ontario",
                      "Prince Edward Island",
                      "Quebec",
                      "Saskatchewan",
                      "Yukon"
                    ].map(i => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </>
                ) : (
                    <>
                      <option value="" disabled>
                        Select
                    </option>
                      {[
                        "Alabama",
                        "Alaska",
                        "Arizona",
                        "Arkansas",
                        "California",
                        "Colorado",
                        "Connecticut",
                        "Delaware",
                        "Florida",
                        "Georgia",
                        "Hawaii",
                        "Idaho",
                        "Illinois",
                        "Indiana",
                        "Iowa",
                        "Kansas",
                        "Kentucky",
                        "Louisiana",
                        "Maine",
                        "Maryland",
                        "Massachusetts",
                        "Michigan",
                        "Minnesota",
                        "Mississippi",
                        "Missouri",
                        "Montana",
                        "Nebraska",
                        "Nevada",
                        "New Hampshire",
                        "New Jersey",
                        "New Mexico",
                        "New York",
                        "North Carolina",
                        "North Dakota",
                        "Ohio",
                        "Oklahoma",
                        "Oregon",
                        "Pennsylvania",
                        "Rhode Island",
                        "South Carolina",
                        "South Dakota",
                        "Tennessee",
                        "Texas",
                        "Utah",
                        "Vermont",
                        "Virginia",
                        "Washington",
                        "West Virginia",
                        "Wisconsin",
                        "Wyoming"
                      ].map(i => {
                        return (
                          <option key={i} value={i}>
                            {i}
                          </option>
                        );
                      })}
                    </>
                  )}
              </Select>
            </FormElement>
            <FormElement>
              <Label htmlFor="zip">Zip code</Label>
              <Input
                id="zip"
                name="zip"
                type="text"
                placeholder={
                  this.state.formData.country === "Canada"
                    ? "A1A1A1"
                    : "12345 or 12345-1234"
                }
                pattern={
                  this.state.formData.country === "Canada"
                    ? "[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]|[A-Za-z][0-9][A-Za-z][0-9][A-Za-z][0-9]"
                    : "([0-9]{5}([-][0-9]{4})?)"
                }
                maxLength={
                  this.state.formData.country === "Canada" ? "7" : "10"
                }
                value={this.state.formData.zip.toUpperCase()}
                onChange={e => this.handleChange(e)}
                required
              />
            </FormElement>
            <FormElement>
              <Label htmlFor="phone">Phone</Label>
              <Input
                id="phone"
                name="phone"
                type="tel"
                placeholder="Phone (optional)"
                value={this.state.formData.phone}
                onChange={e => this.handleChange(e)}
              />
            </FormElement>
          </FormContainer>
        </form>
      </div>
    );
  }
}

export default AddressForm;
