import React, { Component } from "react";
import styled from "react-emotion";
import * as qs from "query-string";
import Subscribe from "./Subscribe";
import AddressForm from "./AddressForm";
import StyledOrderSummary from "./OrderSummary";
import { Button } from "./StyledComponents";
import Alert from "./Alert";
import ReactGA from 'react-ga';

const Navigate = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.75em 0;
  &.mobile {
    display: none;
  }

  @media (max-width: 900px) {
    display: none;
    &.mobile {
      display: flex;
      margin-bottom: 4em;
    }
  }
`;
const Container = styled("div")`
  display: flex;
  margin: 0 3em;
  @media (max-width: 900px) {
    display: block;
    margin: 0 2em;
  }
  @media (max-width: 480px) {
    margin: 0 0.75em;
  }
`;

const Column = styled("div")`
  &.left {
    width: 55%;
    @media (max-width: 900px) {
      width: 100%;
      display: block;
    }
  }
  &.right {
    width: 45%;
    margin-left: 1.5em;
    @media (max-width: 900px) {
      margin: 0 0 0.75em;
      width: 100%;
      display: block;
    }
  }
`;

class Checkout extends Component {
  constructor() {
    super();
    this.state = {
      shipping: {
        email: "",
        name: "",
        company: "",
        address: "",
        unit: "",
        city: "",
        zip: "",
        phone: "",
        state: "",
        country: "United States"
      },
      billing: {
        email: "",
        name: "",
        company: "",
        address: "",
        unit: "",
        city: "",
        zip: "",
        phone: "",
        state: "",
        country: "United States"
      },
      shippingRef: null,
      billingRef: null,
      onShipping: true,
      taxPercent: 0.0,
      usedDiscountCode: "",
      applied: false,
      subtotal: 0,
      total: 0,
      quantity: 0,
      frequency: 1,
      tax: 0,
      type: "",
      internalUse: false,
      skLink: "",
      useShipping: true,
      id: "",
      error: "",
      hasSource: false,
      recurring: true,
      width: window.innerWidth,
    };
    this.updateShipping = this.updateShipping.bind(this);
    this.toggleShipping = this.toggleShipping.bind(this);
    this.getFormData = this.getFormData.bind(this);
    this.getFormRef = this.getFormRef.bind(this);
    this.determineTaxPercent = this.determineTaxPercent.bind(this);
    this.setSubtotal = this.setSubtotal.bind(this);
    this.setTaxAndTotal = this.setTaxAndTotal.bind(this);
    this.setUsedDiscount = this.setUsedDiscount.bind(this);
    this.setSkLink = this.setSkLink.bind(this);
    this.getCustomerData = this.getCustomerData.bind(this);
    this.setError = this.setError.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.segmentIdentify = this.segmentIdentify.bind(this);
    this.setGaTracking = this.setGaTracking.bind(this);
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  componentDidMount() {
    const params = qs.parse(window.location.search);
    let internalUse;
    let price;

    params.internal === "true" ? (internalUse = true) : (internalUse = false);
    const quantity = params.quantity || 1;
    const frequency = params.frequency || 1;
    const type = params.type;
    type === "Essential" || type === "Muncher" ? (price = 209.0) : (price = 119.0);
    const recurring = params.recurring === "false" ? false : true;

    this.setGaTracking(type, quantity, price * quantity);

    this.setState(
      {
        type,
        subtotal: price * quantity,
        quantity,
        frequency,
        internalUse,
        recurring
      },
      () => params.id && this.getCustomerData(params.id)
    );
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  getCustomerData(id) {
    fetch("/customer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ id })
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          this.setState({ ...response });
        } else {
          this.setState({ ...response, onShipping: false, id });
        }
      });
  }

  setError(error) {
    this.setState({ error });
  }

  setSkLink(skLink) {
    this.setState({ skLink });
  }

  setSubtotal(subtotal) {
    this.setState({ subtotal });
  }

  setTaxAndTotal(tax, total) {
    this.setState({ tax, total });
  }

  setUsedDiscount(discountCode) {
    this.setState({ usedDiscountCode: discountCode, applied: true });
  }

  determineTaxPercent() {
    fetch("/tax", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        country: this.state.shipping.country === "Canada" ? "CA" : "US",
        zip: this.state.shipping.zip
      })
    })
      .then(res => res.json())
      .then(taxPercent => this.setState({ taxPercent }));
  }

  toggleShipping() {
    let onShipping = !this.state.onShipping;
    this.setState({ onShipping });
  }

  getFormRef(ref) {
    if (this.state.onShipping) {
      this.setState({ shippingRef: ref });
    } else {
      this.setState({ billingRef: ref });
    }
  }

  getFormData(formData) {
    this.state.onShipping
      ? this.setState({ shipping: formData })
      : this.setState({ billing: formData });
  }

  updateShipping(e, data, shippingForm) {
    e.preventDefault();
    // console.log(data);
    if (shippingForm.checkValidity()) {
      this.setState({ shipping: data, onShipping: false });
    } else {
      shippingForm.reportValidity();
    }
  }

  segmentIdentify(email) {
    window.analytics.identify(email, {
      email: email,
    });
    window.analytics.page()
  }

  setGaTracking(type, quantity, subtotal) {
    ReactGA.plugin.execute('ec', 'addProduct', {
      name: type,
      price: subtotal,
      quantity: quantity
    });
    ReactGA.plugin.execute('ec', 'setAction', 'checkout', { step: 1 })
  }

  render() {
    return (
      <Container>
        {this.state.skLink ? (
          <a href={this.state.skLink}>{this.state.skLink}</a>
        ) : this.state.error ? (
          <Alert message={this.state.error} color="#ff6161">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 246.027 246.027"
              style={{ enableBackground: "new 0 0 246.027 246.027" }}
              xmlSpace="preserve"
              width="1em"
              height="1em"
            >
              <path
                d="M242.751,196.508L143.937,25.358c-4.367-7.564-12.189-12.081-20.924-12.081c-8.735,0-16.557,4.516-20.924,12.081  L3.276,196.508c-4.368,7.564-4.368,16.596,0,24.161s12.189,12.081,20.924,12.081h197.629c8.734,0,16.556-4.516,20.923-12.08  C247.119,213.105,247.118,204.073,242.751,196.508z M123.014,204.906c-8.672,0-15.727-7.055-15.727-15.727  c0-8.671,7.055-15.726,15.727-15.726s15.727,7.055,15.727,15.726C138.74,197.852,131.685,204.906,123.014,204.906z M138.847,137.68  c0,8.73-7.103,15.833-15.833,15.833s-15.833-7.103-15.833-15.833V65.013c0-4.142,3.358-7.5,7.5-7.5h16.667  c4.143,0,7.5,3.358,7.5,7.5V137.68z"
                fill="#ff6161"
              />
            </svg>
          </Alert>
        ) : (
              <>
                <Column className="left">
                  {this.state.onShipping ? (
                    <>
                      <h4>Shipping Address</h4>
                      <AddressForm
                        formData={this.state.shipping}
                        getFormData={this.getFormData}
                        getFormRef={this.getFormRef}
                        onShipping={this.state.onShipping}
                      />
                      {this.state.width <= 900 &&
                        <StyledOrderSummary
                          className="mobile"
                          taxPercent={this.state.taxPercent}
                          onShipping={this.state.onShipping}
                          setUsedDiscount={this.setUsedDiscount}
                          setTaxAndTotal={this.setTaxAndTotal}
                          setSubtotal={this.setSubtotal}
                          subtotal={this.state.subtotal}
                          total={this.state.total}
                          quantity={this.state.quantity}
                          tax={this.state.tax}
                          type={this.state.type}
                          applied={this.state.applied}
                          frequency={this.state.frequency}
                          country={this.state.shipping.country}
                          recurring={this.state.recurring}
                        />
                      }
                      <Navigate>
                        <a
                          href="https://www.hoppier.com/plans"
                          style={{ color: "#f15959", textDecoration: "none" }}
                        >
                          &lt; Return to plans
                    </a>
                        <Button
                          onClick={e => {
                            this.segmentIdentify(this.state.shipping.email);
                            ReactGA.plugin.execute('ec', 'setAction', 'checkout', { step: 1 })
                            this.state.shippingRef.reportValidity();
                            if (this.state.shippingRef.checkValidity()) {
                              this.determineTaxPercent();
                              this.setState({ onShipping: false });
                            }
                          }}
                        >
                          CONTINUE
                    </Button>
                      </Navigate>
                    </>
                  ) : (
                      <Subscribe
                        toggleShipping={this.toggleShipping}
                        getFormData={this.getFormData}
                        getFormRef={this.getFormRef}
                        setError={this.setError}
                        billingRef={this.state.billingRef}
                        taxPercent={this.state.taxPercent}
                        usedDiscountCode={this.state.usedDiscountCode}
                        setUsedDiscount={this.setUsedDiscount}
                        setTaxAndTotal={this.setTaxAndTotal}
                        setSubtotal={this.setSubtotal}
                        setSkLink={this.setSkLink}
                        quantity={this.state.quantity}
                        subtotal={this.state.subtotal}
                        shipData={this.state.shipping}
                        billData={this.state.billing}
                        total={this.state.total}
                        tax={this.state.tax}
                        type={this.state.type}
                        applied={this.state.applied}
                        frequency={this.state.frequency}
                        internalUse={this.state.internalUse}
                        useShipping={this.state.useShipping}
                        id={this.state.id}
                        hasSource={this.state.hasSource}
                        recurring={this.state.recurring}
                      />
                    )}
                </Column>
                <Column className="right">
                  <div
                    style={{
                      position: "sticky",
                      width: "100%",
                      top: "2em"
                    }}
                  >
                    {this.state.width > 900 &&
                      <StyledOrderSummary
                        taxPercent={this.state.taxPercent}
                        onShipping={this.state.onShipping}
                        setUsedDiscount={this.setUsedDiscount}
                        setTaxAndTotal={this.setTaxAndTotal}
                        setSubtotal={this.setSubtotal}
                        subtotal={this.state.subtotal}
                        total={this.state.total}
                        quantity={this.state.quantity}
                        tax={this.state.tax}
                        type={this.state.type}
                        applied={this.state.applied}
                        frequency={this.state.frequency}
                        country={this.state.shipping.country}
                        recurring={this.state.recurring}
                      />
                    }
                  </div>
                </Column>
                {this.state.onShipping && (
                  <Navigate className="mobile">
                    <a
                      href="https://www.hoppier.com/plans"
                      style={{ color: "#f15959", textDecoration: "none" }}
                    >
                      &lt; Return to plans
                </a>
                    <Button
                      className="visible"
                      onClick={e => {
                        this.segmentIdentify(this.state.shipping.email);
                        ReactGA.plugin.execute('ec', 'setAction', 'checkout', { step: 2 });
                        this.state.shippingRef.reportValidity();
                        if (this.state.shippingRef.checkValidity()) {
                          this.determineTaxPercent();
                          this.setState({ onShipping: false });
                        }
                      }}
                    >
                      CONTINUE
                </Button>
                  </Navigate>
                )}
              </>
            )}
      </Container>
    );
  }
}

export default Checkout;
