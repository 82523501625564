import styled from "react-emotion";

const FormContainer = styled("div")`
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  position: relative;
`;
const Label = styled("label")`
  width: 24%;
  display: inline-block;
  padding: 1em;
  cursor: pointer;
  box-sizing: border-box;
`;
const Select = styled("select")`
  background: transparent;
  border: none;
  height: 3em;
  width: 100%;
  font-size: 1em;
  @media (max-width: 425px) {
    font-size: 0.8em;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #edf5ff inset;
  }
`;
const FormElement = styled("div")`
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  align-items: center;
`;
const Input = styled("input")`
  width: 100%;
  padding: 1em 0.5em;
  border: none;
  font-size: 1em;
  box-sizing: border-box;
  background: transparent;
  @media (max-width: 425px) {
    font-size: 0.8em;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #edf5ff inset;
  }
`;
const Button = styled("button")`
  padding: 12px 18px;
  border-radius: 4px;
  border: none;
  color: #fff;
  background-color: #ff6161;
  font-family: 'macho', sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;

  &.mobile-billing {
    display: none;
    @media (max-width: 900px) {
      display: block;
      width: 100%;
      margin: 1em 0 5.5em;
    }
  }

  &.visible {
    @media (max-width: 900px) {
      display: block;
    }
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export { FormContainer, Label, Select, FormElement, Input, Button };
