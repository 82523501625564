import React, { Component } from "react";
import styled from "react-emotion";
import * as qs from "query-string";

import Alert from "./Alert";
import {
  FormContainer,
  FormElement,
  Label,
  Input,
  Button
} from "./StyledComponents";

const Container = styled(FormElement.withComponent("div"))`
  padding: 0 1em;
`;
const DiscountBtn = styled(Button.withComponent("button"))`
  margin-right: 1em;
  padding: 0.5em 0.8em;
`;
const PrimaryText = styled("p")`
  margin: 0;
  &.grey {
    color: #777777;
    margin: 0.5em 0;
  }
`;
const Line = styled("hr")`
  width: 100%;
  background: #dfdfdf;
  border: none;
  height: 1px;
`;
const PlanWrapper = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 1em 0;
`;
const Image = styled("img")`
  width: 25%;
  border-radius: 5px;
`;
const PriceWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coupon: {},
      validDiscount: false,
      error: "",
      discountCode: "",
      currency: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.applyDiscount = this.applyDiscount.bind(this);
    this.getNewPrice = this.getNewPrice.bind(this);
    this.setTaxAndTotal = this.setTaxAndTotal.bind(this);
    this.setCurrency = this.setCurrency.bind(this);
  }

  componentDidMount() {
    const params = qs.parse(window.location.search);
    this.setCurrency()
    if (params.discount && !this.props.applied) {
      this.setState({ discountCode: params.discount }, this.applyDiscount)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.taxPercent !== prevProps.taxPercent ||
      this.props.subtotal !== prevProps.subtotal
    ) {
      this.setTaxAndTotal();
    }

    if (this.props.country !== prevProps.country) {
      this.setCurrency()
    }
  }

  setCurrency() {
    if (this.props.country === "Canada") {
      this.setState({ currency: "CAD" })
    } else {
      this.setState({ currency: "USD" })
    }
  }

  setTaxAndTotal() {
    const tax = parseFloat((this.props.taxPercent / 100) * this.props.subtotal);
    const total = parseFloat(this.props.subtotal) + parseFloat(tax);
    this.props.setTaxAndTotal(tax, total);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  getNewPrice(coupon) {
    let discountedPrice;
    if (coupon.amount_off) {
      discountedPrice =
        parseFloat(this.props.subtotal) - parseFloat(coupon.amount_off / 100);
    } else {
      discountedPrice =
        (parseFloat(this.props.subtotal) * (100 - parseFloat(coupon.percent_off)) / 100)
    }
    return discountedPrice > 0 ? discountedPrice : 0;
  }

  async applyDiscount(e) {
    if (e) e.preventDefault();
    if (this.state.discountCode) {
      fetch("/discount", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          discountCode: this.state.discountCode
        })
      })
        .then(res => res.json())
        .then(response => {
          if (response.valid) {
            const discountedPrice = this.getNewPrice(response.coupon);
            this.setState({ validDiscount: response.valid, error: "" }, () => {
              this.props.setSubtotal(discountedPrice);
              !this.props.onShipping && this.setTaxAndTotal();
              this.props.setUsedDiscount(response.coupon.id);
            });
          } else {
            this.setState({
              validDiscount: response.valid,
              error: response.error
            });
          }
        });
    }
  }

  render() {
    return (
      <>
        <FormContainer className={this.props.className}>
          <Container>
            <h4 style={{ marginLeft: "1em" }}>Order Summary</h4>
          </Container>
          {this.props.type === "sk" || this.props.type === "dn" ? (
            <Container>
              <PlanWrapper>
                <Image
                  alt="smart kitchen"
                  src="https://assets.website-files.com/5cab75b41283e5fbfc0e18e2/5cc313d6bf875e07c92887ea_managed-plan-office-snack-delivery-service-min.png"
                />
                <div style={{ marginLeft: "1em", width: "100%" }}>
                  {this.props.type === "sk" ? <PrimaryText>Hoppier Managed Plan</PrimaryText> : <PrimaryText>Hoppier Plan</PrimaryText>}
                </div>
              </PlanWrapper>
            </Container>
          ) : (
              <>
                <Container>
                  <PlanWrapper>
                    {this.props.type === "Lite" ? (
                      <Image
                        alt="Lite Plan"
                        src="https://assets.website-files.com/5cab75b41283e5fbfc0e18e2/5cc313d6e18ab8440d285270_lite-plan-office-snack-subscription-usa-min.png"
                      />
                    ) : (
                      <Image
                        alt="Essential Plan"
                        src="https://assets.website-files.com/5cab75b41283e5fbfc0e18e2/5cc313d6bf875e77a92887e9_essential-plan-office-snack-subscription-usa-min.png"
                      />
                    )}
                    <div style={{ marginLeft: "1em", width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <PrimaryText>
                          Hoppier {this.props.type} Plan x {this.props.quantity}
                        </PrimaryText>
                        <PrimaryText>
                          {parseFloat(this.props.subtotal).toFixed(2)}
                        </PrimaryText>
                      </div>
                      {this.props.recurring && this.props.frequency === 1 ? (
                        <PrimaryText className="grey">Every week</PrimaryText>
                      ) : (this.props.recurring ? (
                        <PrimaryText className="grey">
                          Every {this.props.frequency} week{this.props.frequency !== '1' && <span>s</span>}
                        </PrimaryText>
                      ) : (
                          <PrimaryText className="grey">
                            One time purchase
                      </PrimaryText>
                        ))}
                    </div>
                  </PlanWrapper>
                </Container>
                {!this.props.applied && (
                  <form>
                    <FormElement>
                      <Label htmlFor="discount">Discount</Label>
                      <Input
                        id="discount"
                        placeholder="Enter discount code"
                        name="discountCode"
                        onChange={e => this.handleChange(e)}
                      />
                      <DiscountBtn
                        className="visible"
                        onClick={e => this.applyDiscount(e)}
                      >
                        OK
                    </DiscountBtn>
                    </FormElement>
                  </form>
                )}
                <Container>
                  <div style={{ display: "block", width: "100%" }}>
                    <PriceWrapper>
                      <PrimaryText className="grey">Subtotal</PrimaryText>
                      <PrimaryText>
                        {parseFloat(this.props.subtotal).toFixed(2)}
                      </PrimaryText>
                    </PriceWrapper>
                    <PriceWrapper>
                      <PrimaryText className="grey">Shipping</PrimaryText>
                      <PrimaryText>FREE</PrimaryText>
                    </PriceWrapper>
                    <PriceWrapper>
                      {!this.props.onShipping && (
                        <>
                          <PrimaryText className="grey">Taxes</PrimaryText>
                          <PrimaryText>
                            {parseFloat(this.props.tax).toFixed(2)}
                          </PrimaryText>
                        </>
                      )}
                    </PriceWrapper>
                    {!this.props.onShipping && (
                      <>
                        <Line />
                        <PriceWrapper>
                          <PrimaryText className="grey">Total</PrimaryText>
                          <PrimaryText>
                            {parseFloat(this.props.total).toFixed(2)} {this.state.currency}
                          </PrimaryText>
                        </PriceWrapper>
                      </>
                    )}
                  </div>
                </Container>
              </>
            )}
        </FormContainer>
        {this.state.error && (
          <div style={{ marginTop: "1em" }}>
            <Alert message={this.state.error} color="#ff6161">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 246.027 246.027"
                style={{ enableBackground: "new 0 0 246.027 246.027" }}
                xmlSpace="preserve"
                width="1em"
                height="1em"
              >
                <path
                  d="M242.751,196.508L143.937,25.358c-4.367-7.564-12.189-12.081-20.924-12.081c-8.735,0-16.557,4.516-20.924,12.081  L3.276,196.508c-4.368,7.564-4.368,16.596,0,24.161s12.189,12.081,20.924,12.081h197.629c8.734,0,16.556-4.516,20.923-12.08  C247.119,213.105,247.118,204.073,242.751,196.508z M123.014,204.906c-8.672,0-15.727-7.055-15.727-15.727  c0-8.671,7.055-15.726,15.727-15.726s15.727,7.055,15.727,15.726C138.74,197.852,131.685,204.906,123.014,204.906z M138.847,137.68  c0,8.73-7.103,15.833-15.833,15.833s-15.833-7.103-15.833-15.833V65.013c0-4.142,3.358-7.5,7.5-7.5h16.667  c4.143,0,7.5,3.358,7.5,7.5V137.68z"
                  fill="#ff6161"
                />
              </svg>
            </Alert>
          </div>
        )}
        {this.props.applied && (
          <div style={{ marginTop: "1em" }}>
            <Alert
              className={this.props.className}
              style={{ display: "flex" }}
              message={`${this.state.discountCode} discount applied`}
              color="#44bd32"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                width="1em"
                height="1em"
                viewBox="0 0 438.533 438.533"
                style={{ enableBackground: "new 0 0 438.533 438.533" }}
                xmlSpace="preserve"
              >
                <g>
                  <path
                    d="M409.133,109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736,9.801,259.058,0,219.273,0   c-39.781,0-76.47,9.801-110.063,29.407c-33.595,19.604-60.192,46.201-79.8,79.796C9.801,142.8,0,179.489,0,219.267   c0,39.78,9.804,76.463,29.407,110.062c19.607,33.592,46.204,60.189,79.799,79.798c33.597,19.605,70.283,29.407,110.063,29.407   s76.47-9.802,110.065-29.407c33.593-19.602,60.189-46.206,79.795-79.798c19.603-33.596,29.403-70.284,29.403-110.062   C438.533,179.485,428.732,142.795,409.133,109.203z M361.445,185.863L206.42,340.889c-3.617,3.62-7.992,5.428-13.134,5.428   c-4.948,0-9.229-1.808-12.847-5.428L77.083,237.539c-3.422-3.429-5.137-7.703-5.137-12.847c0-5.328,1.709-9.709,5.137-13.136   l25.981-25.693c3.621-3.616,7.898-5.424,12.85-5.424s9.235,1.809,12.85,5.424l64.525,64.523l116.485-116.199   c3.617-3.617,7.898-5.426,12.847-5.426c4.945,0,9.233,1.809,12.847,5.426l25.981,25.697c3.432,3.424,5.14,7.801,5.14,13.129   C366.589,178.154,364.881,182.437,361.445,185.863z"
                    fill="#44bd32"
                  />
                </g>
              </svg>
            </Alert>
          </div>
        )}
      </>
    );
  }
}

const StyledOrderSummary = styled(OrderSummary)`
  &.mobile {
    display: none;
  }
  @media (max-width: 900px) {
    display: none;
    &.mobile {
      display: block;
    }
  }
`;

export default StyledOrderSummary;
