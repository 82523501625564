import React from 'react';

const Header = () => (
  <div style={{ margin: "4em 4em 2em" }}>
    <a href="https://hoppier.com">
      <img alt="Hoppier" width="117" src="https://assets.website-files.com/5cab75b41283e5fbfc0e18e2/5cab76ad7177dc5f8ed9b12f_Hoppier-Logo-Black.svg"></img>
    </a>
  </div>
)

export default Header;
