import React from "react";
import styled from "react-emotion";

const FlexContainer = styled("div")`
  display: flex;
  align-items: center;
`;
const AlertContainer = styled("div")`
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.color};
  border-radius: 5px;
  padding: 0 1em;
  margin-bottom: 1em;
`;

const Alert = props => (
  <AlertContainer color={props.color} className={props.className}>
    <FlexContainer>
      {props.children}
      <h4
        style={{
          marginLeft: "1em",
          color: `${props.color}`
        }}
      >
        {props.message}
      </h4>
    </FlexContainer>
  </AlertContainer>
);

export default Alert;
