import React, { Component } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from "react-stripe-elements";
import styled, { css, keyframes } from "react-emotion";
import Alert from "./Alert";
import { FormContainer, Label, FormElement, Button } from "./StyledComponents";
import AddressForm from "./AddressForm";
import StyledOrderSummary from "./OrderSummary";
import ReactGA from 'react-ga';

const PrimaryText = styled("p")`
  color: #777777;
  margin: 0;
`;
const EditShipping = styled("h3")`
  color: #ff6161;
  cursor: pointer;
  fontweight: normal;
`;
const LoadingContainer = styled("div")`
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  position: absolute;
  top: 0;
  z-index: 999;
  justify-content: center;
  align-items: center;
`;
const LoadingBackground = styled("div")`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  background-color: #fff;
  opacity: 0.5;
  &.longPage {
    height: 120vh;
  }
`;
const LoadingIconContainer = styled("div")`
  display: flex;
  width: 30em;
  height: 20em;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
const loadingBars = {
  one: css`
    #DF4646;
    animation-delay: 0.1s;
  `,
  two: css`
    #FF6161;
    animation-delay: 0.2s;
  `,
  three: css`
    #FF9999;
    animation-delay: 0.3s;
  `
};
const barAnimation = keyframes`
  69%{
    height: 5.2em;
  }
  70% {
    height: 7.2em;
  }
  to {
    height: 5.2em;
  }
`;
const LoadingBar = styled("div")`
  display: flex;
  width: 2em;
  height: 5.2em;
  margin: 0% 2%;
  animation: ${barAnimation} 1.4s;
  animation-iteration-count: infinite;
  background-color: ${props => loadingBars[props.bar]};
`;

class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      awaitComplete: false,
      billData: this.props.shipData,
      complete: false,
      addressComplete: true,
      useShipping: true,
      error: "",
      skLink: "",
      btnText: "PLACE MY ORDER",

      // Real Box Plans
      boxPlans: {
        nibbler: {
          "1": "plan_DSxwLxZqlPous3",
          "2": "plan_DSxxWCdpXaFXBr",
          "4": "plan_DSxx2zizvJ3Y4S"
        },
        muncher: {
          "1": "plan_DSxfEeGfy5Yv3g",
          "2": "plan_DSxh3dfr8n9AAB",
          "4": "plan_DSxi4EJ7DKMgw0"
        },
        nibblerUS: {
          "1": "plan_E6hZJOjELwBpf4",
          "2": "plan_E6haLBSj3X2gEv",
          "4": "plan_E6hdOZOO3du3pp",
        },
        muncherUS: {
          "1": "plan_E6hgg1DVcEn75X",
          "2": "plan_E6hh1UazvvUtxU",
          "4": "plan_E6hiEFJyeBFBul",
        }
      }

      // Test Box Plans
      // boxPlans: {
      //   nibbler: {
      //     "1": "plan_E6ggYeJGj0Z7xw",
      //     "2": "plan_E6gh93ktMVAyH8",
      //     "4": "plan_E6hqxmKyOgmpxb"
      //   },
      //   muncher: {
      //     "1": "plan_E6gjAAS5F98RhP",
      //     "2": "plan_E6gksTZDNxjiGA",
      //     "4": "plan_E6hrE8cdSyNOqk"
      //   },
      //   nibblerUS: {
      //     "1": "plan_E6gIPwxuYI51QS",
      //     "2": "plan_E6gJ5jmynKjPy2",
      //     "4": "plan_E6hsbSedAEs6RR",
      //   },
      //   muncherUS: {
      //     "1": "plan_E6fuBATMvaGoLb",
      //     "2": "plan_E6gGHHdHhGBruW",
      //     "4": "plan_E6hsYROrGDkLGT",
      //   }
      // }
    };
    this.autocomplete = this.autocomplete;
    this.billing = React.createRef();
    this.address = React.createRef();
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleUseShipping = this.toggleUseShipping.bind(this);
    this.stripeElementChange = this.stripeElementChange.bind(this);
    this.handlePlaceChange = this.handlePlaceChange.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.createCustomer = this.createCustomer.bind(this);
    this.finalizeCustomer = this.finalizeCustomer.bind(this);
    this.onboardCustomer = this.onboardCustomer.bind(this);
  }

  // allows page to scroll to top when component is rendered on mobile
  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.useShipping) {
      this.setState({ billData: this.props.billData, useShipping: false });
    }

    if (this.props.internalUse) {
      this.setState({ btnText: "NEXT" })
    } else if (this.props.hasSource) {
      this.setState({ btnText: "UPDATE" })
    } else if (this.props.type === "sk" && !this.props.internalUse) {
      this.setState({ btnText: "SIGN UP" })
    } else {
      this.setState({ btnText: "PLACE MY ORDER" })
    }
    // this.props.internalUse && this.setState({ cardComplete: true });
  }

  componentDidUpdate(prevState) {
    if (this.props.billData !== prevState.billData) {
      this.setState({ billData: this.props.billData });
    }
  }

  toggleUseShipping(e) {
    if (e.target.checked) {
      this.setState({
        billData: this.props.shipData,
        useShipping: e.target.checked,
        addressComplete: true
      });
    } else {
      this.setState({
        billData: this.props.billData,
        useShipping: e.target.checked,
        addressComplete: false
      });
    }
  }

  handlePlaceChange() {
    let places = this.autocomplete.getPlace();
    let city, state, country, zip;

    places.address_components.forEach(place => {
      place.types.forEach(type => {
        switch (type) {
          case "locality":
            city = place.long_name;
            break;
          case "administrative_area_level_1":
            state = place.long_name;
            break;
          case "country":
            country = place.long_name;
            break;
          case "postal_code":
            zip = place.long_name.replace(/\s/g, "");
            break;
          default:
            break;
        }
      });
    });
    let billData = { ...this.state.billData };
    if (country === "Canada" && zip && zip.length >= 6) {
      billData.address = places.name;
      billData.city = city;
      billData.state = state;
      billData.country = country;
      billData.zip = zip;
    } else if (country === "United States" && zip && zip.length === 5) {
      billData.address = places.name;
      billData.city = city;
      billData.state = state;
      billData.country = country;
      billData.zip = zip;
    } else {
      billData.address = places.name;
      billData.city = city;
      billData.state = state;
      billData.country = country;
    }
    this.setState({ billData });
  }

  handleChange(e) {
    const key = e.target.name;
    const value = e.target.value;
    let billData = { ...this.state.billData };
    billData[key] = value;
    this.setState({ billData });
    this.checkAddress(this.form);
  }

  checkAddress() {
    this.props.billingRef.reportValidity();
    this.setState({ addressComplete: this.props.billingRef.checkValidity() });
  }

  stripeElementChange(element) {
    if (element.complete && !element.error) {
      this.setState({ error: "" });
    }
  }

  async subscribe(billData) {
    let token;
    token = await this.props.stripe.createToken({
      name: billData.name,
      address_city: billData.city,
      address_country: billData.country,
      address_line1: billData.address,
      address_line2: billData.unit,
      address_state: billData.state,
      address_zip: billData.zip
    });
    // console.log(token);
    if (token.error) {
      this.setState({
        error: token.error.message
      });
    } else {
      if (this.state.addressComplete) {
        window.scrollTo(0, 0);
        this.setState({ awaitComplete: true });

        let plan;

        if (this.props.shipData.country === "Canada") {
          if (this.props.type === "Lite" || this.props.type === "Nibbler") {
            plan = this.state.boxPlans.nibbler[this.props.frequency];
          } else {
            plan = this.state.boxPlans.muncher[this.props.frequency];
          }
        } else {
          if (this.props.type === "Lite" || this.props.type === "Nibbler") {
            plan = this.state.boxPlans.nibblerUS[this.props.frequency];
          } else {
            plan = this.state.boxPlans.muncherUS[this.props.frequency];
          }
        }
        // console.log(plan);
        fetch("/subscribe", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            isRecurring: this.props.recurring,
            discountCode: this.props.usedDiscountCode,
            shipData: this.props.shipData,
            source: token.token,
            freq: this.props.frequency,
            quantity: this.props.quantity,
            taxPercent: this.props.taxPercent,
            plan
          })
        })
          .then(res => res.json())
          .then(response => {
            if (response.subscribed) {
              this.setState({ complete: true });
            } else {
              this.setState({
                awaitComplete: false,
                error: response.error
              });
            }
          });
      }
    }
  }

  async createCustomer(billData) {
    if (this.state.addressComplete) {
      window.scrollTo(0, 0);
      this.setState({ awaitComplete: true });

      await fetch("/internal", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: this.state.useShipping
          ? JSON.stringify({ shipData: this.props.shipData })
          : JSON.stringify({
            shipData: this.props.shipData,
            billData: billData
          })
      })
        .then(res => res.json())
        .then(response => {
          if (response.customerCreated) {
            this.setState({ awaitComplete: false }, () =>
              this.props.setSkLink(response.skLink)
            );
          } else {
            this.setState({ awaitComplete: false, error: response.error });
          }
        });
    }
  }
  async finalizeCustomer(billData) {
    let token;
    token = await this.props.stripe.createToken({
      name: billData.name,
      address_city: billData.city,
      address_country: billData.country,
      address_line1: billData.address,
      address_line2: billData.unit,
      address_state: billData.state,
      address_zip: billData.zip
    });
    // console.log(token);
    if (token.error) {
      this.setState({
        error: token.error.message
      });
    } else {
      if (this.state.addressComplete) {
        window.scrollTo(0, 0);
        this.setState({ awaitComplete: true });
        await fetch("/finalize", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            shipData: this.props.shipData,
            source: token.token,
            customerId: this.props.id
          })
        })
          .then(res => res.json())
          .then(response => {
            if (response.customerUpdated) {
              this.setState({ complete: true });
            } else {
              this.setState({ awaitComplete: false, error: response.error });
            }
          });
      }
    }
  }

  async onboardCustomer(billData) {
    let token = await this.props.stripe.createToken({
      name: billData.name,
      address_city: billData.city,
      address_country: billData.country,
      address_line1: billData.address,
      address_line2: billData.unit,
      address_state: billData.state,
      address_zip: billData.zip
    });
    // console.log(token);
    if (token.error) {
      this.setState({
        error: token.error.message
      });
    } else {
      if (this.state.addressComplete) {
        window.scrollTo(0, 0);
        this.setState({ awaitComplete: true });
        await fetch("/onboarding", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            shipData: this.props.shipData,
            source: token.token,
          })
        })
          .then(res => res.json())
          .then(response => {
            if (response.customerCreated) {
              this.setState({ complete: true });
            } else {
              this.setState({ awaitComplete: false, error: response.error });
            }
          });
      }
    }
  }

  async submit(e) {
    e.preventDefault();
    ReactGA.event({
      category: 'Checkout',
      action: 'purchase',
      label: this.props.shipData.email
    });

    if (!this.state.useShipping) {
      await this.checkAddress();
    }
    if (this.props.internalUse) {
      this.createCustomer(this.state.billData);
    } else if ((this.props.type === "sk" || this.props.type === "dn") && this.props.id) {
      this.finalizeCustomer(this.state.billData);
    } else if (this.props.type === "dn" && !this.props.id) {
      this.onboardCustomer(this.state.billData);
    } else if (this.props.type === "Lite" || this.props.type === "Essential" ||
              this.props.type === "Nibbler" || this.props.type === "Muncher") {
      this.subscribe(this.state.billData);
      ReactGA.plugin.execute('ec', 'setAction', 'checkout', { step: 3 });
    } else {
      this.props.toggleShipping();
      this.props.setError("Invalid request");
    }
  }

  render() {
    this.state.complete &&
      window.location.replace("https://www.hoppier.com/ca/thank-you/purchase");

    return (
      <div>
        {this.state.awaitComplete ? (
          <LoadingContainer className="loading">
            <LoadingBackground
              className={!this.state.useShipping ? "longPage" : ""}
            />
            <LoadingIconContainer>
              <LoadingBar bar="one" />
              <LoadingBar bar="two" />
              <LoadingBar bar="three" />
            </LoadingIconContainer>
          </LoadingContainer>
        ) : null}
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4>Customer Information</h4>
            <EditShipping onClick={() => this.props.toggleShipping()}>
              Edit
            </EditShipping>
          </div>
          <PrimaryText>{this.props.shipData.email}</PrimaryText>
          <PrimaryText>{this.props.shipData.name}</PrimaryText>
          <PrimaryText>{this.props.shipData.address}</PrimaryText>
          <PrimaryText>{this.props.shipData.unit}</PrimaryText>
          <PrimaryText>{`${this.props.shipData.city}, ${
            this.props.shipData.state
            }, ${this.props.shipData.zip}`}</PrimaryText>
          <PrimaryText>{this.props.shipData.country}</PrimaryText>
          <PrimaryText>{this.props.shipData.phone}</PrimaryText>
        </div>
        <hr style={{ color: "#777777", margin: "1.75em 0" }} />
        <h4>Billing and payment</h4>
        {this.state.error && (
          <>
            {window.scrollTo(0, 0)}
            <Alert message={this.state.error} color="#ff6161">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 246.027 246.027"
                style={{ enableBackground: "new 0 0 246.027 246.027" }}
                xmlSpace="preserve"
                width="1em"
                height="1em"
              >
                <path
                  d="M242.751,196.508L143.937,25.358c-4.367-7.564-12.189-12.081-20.924-12.081c-8.735,0-16.557,4.516-20.924,12.081  L3.276,196.508c-4.368,7.564-4.368,16.596,0,24.161s12.189,12.081,20.924,12.081h197.629c8.734,0,16.556-4.516,20.923-12.08  C247.119,213.105,247.118,204.073,242.751,196.508z M123.014,204.906c-8.672,0-15.727-7.055-15.727-15.727  c0-8.671,7.055-15.726,15.727-15.726s15.727,7.055,15.727,15.726C138.74,197.852,131.685,204.906,123.014,204.906z M138.847,137.68  c0,8.73-7.103,15.833-15.833,15.833s-15.833-7.103-15.833-15.833V65.013c0-4.142,3.358-7.5,7.5-7.5h16.667  c4.143,0,7.5,3.358,7.5,7.5V137.68z"
                  fill="#ff6161"
                />
              </svg>
            </Alert>
          </>
        )}
        <FormContainer>
          {this.props.internalUse && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: "1",
                backgroundColor: "#dcdcdc8c",
                border: "1px solid black"
              }}
            >
              <h2>Disabled For Internal Use</h2>
            </div>
          )}
          <FormElement>
            <Label htmlFor="card">Card number</Label>
            <div style={{ width: "73%", display: "inline-block" }}>
              <CardNumberElement
                id="card"
                className="fs-hide"
                onChange={element =>
                  this.stripeElementChange(element, "numComplete")
                }
              />
            </div>
          </FormElement>
          <FormElement>
            <Label htmlFor="expiry">Expiration</Label>
            <div style={{ width: "73%", display: "inline-block" }}>
              <CardExpiryElement
                id="expiry"
                className="fs-hide"
                onChange={element =>
                  this.stripeElementChange(element, "expiryComplete")
                }
              />
            </div>
          </FormElement>
          <FormElement>
            <Label htmlFor="cvc">CVC</Label>
            <div style={{ width: "73%", display: "inline-block" }}>
              <CardCVCElement
                id="cvc"
                className="fs-hide"
                onChange={element =>
                  this.stripeElementChange(element, "cvcComplete")
                }
              />
            </div>
          </FormElement>
        </FormContainer>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "1em" }}
        >
          <input
            type="checkbox"
            checked={this.state.useShipping}
            onChange={e => this.toggleUseShipping(e)}
            id="sameAddress"
            name="useShipping"
          />
          <label htmlFor="sameAddress" style={{ marginLeft: "0.5em" }}>
            My billing information is the same as my shipping information
          </label>
        </div>
        {!this.state.useShipping && (
          <>
            <h4>Billing Address</h4>
            <AddressForm
              formData={this.props.billData}
              useShipping={this.state.useShipping}
              getFormData={this.props.getFormData}
              getFormRef={this.props.getFormRef}
            />
          </>
        )}
        <div style={{ margin: "1em 0" }}>
          <StyledOrderSummary
            className="mobile"
            taxPercent={this.props.taxPercent}
            onShipping={false}
            setUsedDiscount={this.props.setUsedDiscount}
            setTaxAndTotal={this.props.setTaxAndTotal}
            setSubtotal={this.props.setSubtotal}
            setTotal={this.props.setTotal}
            subtotal={this.props.subtotal}
            total={this.props.total}
            quantity={this.props.quantity}
            tax={this.props.tax}
            type={this.props.type}
            applied={this.props.applied}
            frequency={this.props.frequency}
            country={this.props.shipData.country}
            recurring={this.props.recurring}
          />
          <Button onClick={e => this.submit(e)}>{this.state.btnText}</Button>
          <Button className="mobile-billing" onClick={e => this.submit(e)}>
            {this.state.btnText}
          </Button>
        </div>
      </div>
    );
  }
}

export default injectStripe(Subscribe);
