import React, { Component } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import ReactGA from 'react-ga';
import Header from "./Header";
import Checkout from "./Checkout";

const innerScripts = `
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
    analytics.load("YXWmul3RTKz16P54EicpSWfDvICD3pkQ");
    analytics.page();
  }}();
  //Intercom style adjustments
  window.intercomSettings = {
    app_id: 'rw5n0dgj',
    alignment: 'right',
    horizontal_padding: 20,
    vertical_padding: 20
  };
`;
ReactGA.initialize('UA-88969869-3');
ReactGA.plugin.require('ec');
ReactGA.set({ hostname: 'checkout.hoppier.com' });
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  componentDidMount() {
    const s = document.createElement('script');
    s.innerHTML = innerScripts;
    document.body.appendChild(s);
  }
  render() {
    return (
      <StripeProvider apiKey="pk_live_EUDPtGIH2xfcpT16tDgPTylG">
        {/* <StripeProvider apiKey="pk_test_dh9J6OHbFp1zNXUF8GBeRhWo"> */}
        <div>
          <Header />
          <Elements>
            <Checkout />
          </Elements>
        </div>
      </StripeProvider>
    );
  }
}

export default App;
